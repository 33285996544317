<template>
  <div style="height: 95%; overflow: auto;">
    <div v-if="tasks.open?.length">
        <p>open tasks</p>
        <div v-for="task in tasks.open" :key="task.id"> 
            <open-task-card @reload-list="emitReload" :task="task"></open-task-card><br/>
        </div>
    </div>
    <div  v-if="tasks.closed?.length">
        <p v-if="tasks.closed.length">closed tasks</p>
        <div v-for="task in tasks.closed" :key="task.id"> 
            <closed-task-card @reload-list="emitReload" :task="task"></closed-task-card><br/>
        </div>
    </div>
  </div>
</template>

<script>
import OpenTaskCard from "@/components/guest_cards/tasks/OpenTaskCard";
import ClosedTaskCard from "@/components/guest_cards/tasks/ClosedTaskCard";
export default {
    name:'TaskList',
    components:{
        OpenTaskCard,
        ClosedTaskCard
    },
    props:['tasks'],
    data() {
        return {
        }
    },
    emits: ['reload-tasks'],
    methods: {
        emitReload() {
            this.$emit('reload-tasks');
        },
    },
}
</script>
