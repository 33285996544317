<template>
    <div class="h-5-6">
        <div class="h-top-half overflow-y-auto">
            <div v-for="(coResidents, index) in coResidents" :key="'coResidents-' + index">
                <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                    <div @click="editCoresident(coResidents.coresident_id)">
                       <span class="font-medium">{{ coResidents.first_name }} {{ coResidents.last_name }}</span> - {{ (coResidents.display_order === 0 ? " Owner" : "Co-resident")}}
                    </div>
                    <div v-if="coResidents.display_order !== 0">
                        <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="remove(coResidents.coresident_id)"></icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full bg-gray-100 p-4 border mt-1 mb-3 h-bot-half">
            <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                Add new co-resident
            </p>
            <div class="form-row">
                <div class="form-col">
                    <label for="first_name">first name</label>
                    <input type="text" class="form-input" id="first_name" v-model="newCoResident.first_name" />
                </div>
                <div class="form-col">
                    <label for="last_name">last_name</label>
                    <input type="text" class="form-input" id="last_name" v-model="newCoResident.last_name" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="phone">phone</label>
                    <input type="text" class="form-input" id="phone" maxlength="14"  v-model="newCoResident.phone.phone_number" @input="formatPhoneInput" />
                </div>
                <div class="form-col">
                    <label for="email">email</label>
                    <input type="text" class="form-input" id="email" v-model="newCoResident.email.email_address" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-col">
                    <label for="phone">contact preference</label>
                    <div class="inline">
                        <checkbox-input label="email" v-model="contactPreferences.email" class="mr-4" />
                        <checkbox-input label="sms" v-model="contactPreferences.sms" class="mr-4" />
                        <checkbox-input label="phone" v-model="contactPreferences.phone" />
                    </div>
                </div>
            </div>

            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="clearAdd">clear form</button>
                <button class="btn-primary ml-2" @click="addCoResident">{{newCoResident.coresident_id ? 'update resident' : 'add'}}</button>
            </div>
        </div>
        <div class="text-right mt-4 footer">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="update">save edits</button>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import {v4} from "uuid";
import Icon from "@/components/ui/Icon";
import CheckboxInput from "@/components/ui/CheckboxInput";

const defaultNewCoResident = {
    first_name: null,
    last_name: null,
    phone: {
        phone_number: null
    },
    email: {
        email_address: null
    },
};

const defaultContactPreference = {
    email: false,
    sms: false,
    phone: false
};
export default {
    components: {
        Icon,
        CheckboxInput,
    },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            coResidents: [],
            newCoResident: {...defaultNewCoResident},
            contactPreferences: {...defaultContactPreference},
            toRemove: [],
            toAdd: [],
            toUpdate: {},
        }
    },
    emits: ['co-residents-edited'],
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        formatPhoneInput(){ 
                var x = this.newCoResident.phone.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.newCoResident.phone.phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        remove(id) {
            let residentIndex = -1;
            let searchResident = this.coResidents.find((item, index) => {
                let found = item.coresident_id === id;
                if (found) {
                    residentIndex = index;
                    return true;
                }
                return false;
            });
            if (confirm("Are you sure you want to remove this resident?")) {
                this.coResidents.splice(residentIndex, 1);
                this.toRemove.push(id);
                console.log("removing", searchResident);
                console.log("new residents", this.coResidents);
            }
        },
        clearAdd() {
            this.newCoResident = {...defaultNewCoResident};
            this.contactPreferences = {...defaultContactPreference};
        },
        addCoResident() {
            let id = v4(),
                coresidentIndex = -1;

            if (this.newCoResident.coresident_id) {
                coresidentIndex = this.coResidents.findIndex(resident => resident.coresident_id === this.newCoResident.coresident_id);
                this.coResidents[coresidentIndex] = {
                    ...this.newCoResident,
                    contact_preference: JSON.stringify(this.newCoResident.contact_preference)
                }
                this.toUpdate[this.newCoResident.coresident_id] = this.coResidents[coresidentIndex];
            } else {
                let residentToAdd = {
                    ...this.newCoResident,
                    coresident_id: id,
                    contact_preference: JSON.stringify(this.contactPreferences),
                }
                this.coResidents.push(residentToAdd);
                this.toAdd.push(residentToAdd);
            }

            this.clearAdd();



        },
        editCoresident(coresidentId) {
            let searchCoresident = this.coResidents.find(item => item.coresident_id === coresidentId);
            this.contactPreferences = JSON.parse(searchCoresident.contact_preference);
            searchCoresident.phone = searchCoresident.phone || {
                phone_number: ''
            }
            searchCoresident.email = searchCoresident.email || {
                email_address: ''
            }
            this.newCoResident = {...searchCoresident, contact_preference: this.contactPreferences};
        },
        close() {
            this.coResidents = JSON.parse(JSON.stringify(this.guestCard.coresidents));
            this.$emit('co-residents-edited');
            this.isEditing = false;
            this.toRemove = [];
            this.toAdd = [];
            this.toUpdate = [];
        },
        update() {
            this.isEditing = true;
            let dataToUpdate = {
                coresidents: JSON.parse(JSON.stringify(this.coResidents)),
            },
            updateRequest = {
                coresidents: {
                    to_add: this.toAdd,
                    to_delete: this.toRemove,
                    to_modify: this.toUpdate
                }
            }
            this.$guestCardDataProvider
                .update('guestCardDetail', {
                    guestCardId: this.$route.params.guestCardId,
                    data: updateRequest,
                })
            this.updateGuestCardInStore(dataToUpdate);
            this.close();
        },
        // async getCoresidentContacts() {
        //     let ids = this.guestCard.coresidents.map(value => value.contact_id);
        //     this.$guestCardDataProvider
        //         .list('contacts', {'ids[]': ids})
        //         .then(response => {
        //             this.guestCard.coresidents.forEach((value, index) => {
        //                 let contactId = value.contact_id;
        //                 this.guestCard.coresidents[index] = {
        //                     ...this.guestCard.coresidents[index],
        //                     ...response[contactId]
        //                 }
        //             });
        //             this.isCoresidentsContactFetched = true;
        //         })
        //         .catch();
        // },
    },
    created() {
        this.coResidents = JSON.parse(JSON.stringify(this.guestCard.coresidents));
    }
}
</script>
<style scoped>
    .footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .h-top-half {
        max-height: 35%;
    }

    .h-bot-half {
        height: 67%;
    }

    .h-full {
        height: 100%;
    }
</style>