<template>
  <div class="card">
       <span class="flex justify-right text-blue-500 text-sm cursor-pointer select-none" @click="isOpen = !isOpen">
                    <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
                    <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
                    <span class="mb-1">add a note</span>
        </span>
        <loader :loading="loading" :backdrop="true"/>
       <Form :submit="saveNote">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" v-if="isOpen">
               
            <div class="p-4 mt-1 mb-3 " >
                 <div>
                     <div>

                         <label for="description">{{"description"}}</label>
                         <textarea v-model="note.content" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancel" class="btn-primary " type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">  
                        <button @click="handleSave" class="btn-primary btn-solid " type="submit"> save</button>
                      </div>
                    </div>
             
                </div>
            </div>
        </Form>
  </div> 
</template>

<script>
import { mapGetters, mapActions} from "vuex";
import Form from "@/components/form/Form";
import Icon from "@/components/ui/Icon";
import moment from "moment";
import Loader from '@/components/ui/Loader';

export default {
    name:'AddNoteCard',
    components:{Icon,Form,Loader},
    data(){
        return {
            uuid: null,
            note:{
              guest_card_id:null,
              content: null,
            },
            isOpen:false,
            loading:false
        }
    },
    mounted(){
         
    },
    emits: ['reload-data'],
    computed: {
        ...mapGetters({
            getGuestCardUUID: 'guest_cards/getGuestCardUUID'
        }),
      
    },
    methods: {
         ...mapActions({
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
        handleSave(){
          this.saveNote()
        },
        loadTours(){
           
        },
        saveNote(){
            this.loading = true

            this.note.guest_card_id = this.uuid,
            this.$guestCardDataProvider.create('notes',this.note).then((response) => {
                if(response){
                    this.notifySuccess('Note saved successfully.')
                    this.$emit('reload-data',true)
                    this.loading = false
                    this.resetTour()
                }
                 
            }).catch()
            this.isOpen = false
        },
        resetTour(){
             this.note = {
              agent_display_name : 'Temporary Agent',
              showing_date: moment().format('YYYY-MM-DD'),
              units:[],
              note_type:null,
              description: null
            }
        },
        cancel(){
            this.resetTour()
            this.isOpen = false
        }
    },
    created() {
        this.uuid = this.getGuestCardUUID;
    }
}
</script>
<style scoped>
    .label{
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
    .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
</style>