<template>
    <div v-if="prices">
        <div>
            <list
                :if="columns && prices"
                :columns="columns"
                :items="prices"
                :css="{ tableClass: 'table table--striped', bodyRowClass: 'row' }"

            >
                <template v-slot:column:duration="{value}">
                    {{ value }} hours
                </template>
            </list>
        </div>
    </div>
</template>

<script>
import List from "@/components/list/List";

export default {
    components: {
        List
    },
    props: {
        prices: {
            type: Array,
            required: true,
            default: () => { return [] }
        },

    },
    data: () => {
        return {
            showPetInfoForm: false,
            columns: [
                {
                    name: 'unit',
                    title: 'unit',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'price',
                    title: 'price',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'duration',
                    title: 'offer good for',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'month',
                    title: 'lease duration',
                    class: 'w-3/12 text-center',
                    sortable: false
                },
            ],
        }
    },
    methods: {
    },
    created() {
    }
}
</script>
