<template>
  <div >
       <div :class="'w-1/8  p-4 border h-full overflow-x relative group card' " >
                <icon
                    name="edit"
                    class="w-4 h-4 block absolute right-2 opacity-0 cursor-pointer group-hover:opacity-50"
                    @click="editMode"
                />
            <Form :submit="handleSave" v-if="edit">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" >
               
            <div class="p-4 mt-1 mb-3 " >
            
                 
                 <div class="flex mt-4">
                        <div class="form-col">
                               <label for="date">{{"date"}}</label>
                               <input type="date" name="date" class="form-input" v-model="Task.due_date" />
                        </div>
                        <div class="form-col">
                               <label for="users">{{"assigned to"}}</label>
                               <dropdown :options="profileUsers" name="users" :value="Task.agent" v-model="Task.agent"  />
                        </div>
                 </div>
                 <br />
                 <div>
                     <div>

                         <label for="description">{{"description"}}</label>
                         <textarea v-model="Task.description" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancelEdit" class="btn-primary "  type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">  
                        <button @click="saveTask" class="btn-primary btn-solid" type="submit">save</button>
                      </div>
                    </div>
             
                </div>
            </div>
            </Form>
            <div class="flex mt-4" v-else>
                   <div class="w-1/2 relative group">
                       <p class="label">date</p>
                       <p class="value">{{Task.due_date}}</p>       
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">assigned to</p>
                       <p class="value">{{Task.agent_display_name}}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">last modified</p>
                       <p class="value">{{Task.updated_at}}</p>
                   </div>
                   <!-- <div class="w-1/2 relative group">
                       <p class="label">unit(s)</p>
                       <div class="value flex">
                            <p v-for="(value,index) in tour.units" :key="index">
                            {{ value + ((index + 1) == tour.units.length ? " " : ", ")}}
                            </p>
                       </div>
                   </div> -->
            </div>
            <br />
            <div v-if="!edit">
                <div>
                     <p class="label">description</p>
                     <p class="value">  {{  Task.description}}</p>
                </div>
            </div>
            <br />
              <button @click="taskComplete" class="btn-primary " type="button">
              <icon name="checkmark" class="w-4 h-4 inline-block mr-1"></icon>
               mark complete
              </button>
            <br />
        </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Icon from "@/components/ui/Icon";
import Form from "@/components/form/Form";
import Dropdown from "@/components/ui/Dropdown";
export default {
    name:'OpenTaskCard',
    components:{Icon,Form,Dropdown},
    props:['task'],
    data(){
        return {
            Task: this.task,
            edit: false,
            profileUsers:[]
        }
    },
    mounted(){
         
    },
    emits: ['reload-list'],
    computed: {
        ...mapGetters( {
            profiles   : 'auth/profiles',
        })
    },
    methods: {
        ...mapActions({
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
        saveTask(){
            this.Task.guest_card_id = this.uuid;
            let params = {
                id : this.Task.id ,
                data : {
                    task_id     : this.Task.id,
                    description : this.Task.description,
                    agent_name  : this.profileUsers.find(item => item.key == this.Task.agent).value,
                    due_date    : this.Task.due_date,
                }
            }
            this.$guestCardDataProvider.update('tasks',params).then(response => {
                console.log(response)
                if (response.success) {
                    this.edit = false;
                    this.emitHandler(response);
                } else {
                  this.notifyError(response.message);
                }
                 
            })
            .catch();
       
        },
        emitHandler(response) {
            console.log(response)
              if(response.success){
                  this.notifySuccess(response.message);
                  this.$emit('reload-list');
              }else{
                  this.notifyError(response.message)
              }
        },
        handleSave(){
        },
        cancelEdit(){
            this.edit=false
        },
        editMode(){
            this.edit = true;
            this.Task.agent = this.profileUsers.find(item => item.value == this.task.agent_display_name).key;
            this.Task.due_date = moment(this.task.due_date).format("MM/DD/YYYY");
        },
        taskComplete(){
          let params = {
                id : this.Task.id,
                data : {
                    task_id     : this.Task.id,              
                }
            }
          this.$guestCardDataProvider.update('taskCompleted',params).then(this.emitHandler).catch();
        }
    },
    created() {
        let options = [];
        this.profiles.forEach(profile => {
            options.push({
                key: profile.profileId,
                value: profile.firstName +" "+ profile.lastName
            });
        });
        this.profileUsers = options;
        this.Task.due_date = moment(this.task.due_date).format("MM/DD/YYYY");
        this.Task.updated_at = moment(this.task.updated_at).format("MM/DD/YYYY");
    }
}
</script>
<style scoped>
    .label{
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .card{
      padding : 20px;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
     .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
</style>