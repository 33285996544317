<template>
  <div style="height: 95%; overflow: auto;">
    <loader :loading="loading" :backdrop="true"/>
      <div v-for="note in notes" :key="note.id"> 
       <!--    {{note}} -->
        <note-card  @reload-data="loadNotes" :noteData="note"></note-card><br/>
      </div>
      <br/>
    
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import Loader from '@/components/ui/Loader';
import NoteCard from "@/components/guest_cards/notes/NoteCard";

export default {
    name:'NotesList',
    components:{NoteCard,Loader},
    data(){
        return {
           uuid:null,
           notes:[
               {
                   content: '',
                   agent_name: ''
               }
            ],
                
        }
        
    },
  
    computed: {
        
        ...mapGetters({
            getGuestCardUUID: 'guest_cards/getGuestCardUUID'
        }),
     
    },
    methods: {
       loadNotes(){
              this.loading = true
              this.$guestCardDataProvider.get('notes',{guest_card_id:this.uuid}).then((response) => {
                    this.notes = response
                    this.loading = false
            }).catch()
        },
    },
    mounted(){
            this.uuid = this.getGuestCardUUID;
            this.loadNotes();
    },
     created() {
      
    }
}
</script>