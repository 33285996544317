<template>
  <div class="">
       <div :class="'w-1/8  p-4 border h-full overflow-x relative group card' " >
                <icon
                    name="edit"
                    class="w-4 h-4 block absolute right-2 opacity-0 cursor-pointer group-hover:opacity-50"
                    @click="editMode"
                />
            <Form :submit="saveTour" v-if="edit">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" >
               
            <div class="p-4 mt-1 mb-3 " >
            
                 
                 <div class="flex mt-4">
                        <div class="form-col date-time-inputs">
                               <label class="label" for="date">{{"date"}}</label>
                               <input type="date" name="date" class="form-input"   v-model="Tour.showing_date" />
                               <br/>
                               <div class="flex">
                                  <div class="form-col ">
                                      <label class="label" for="from">{{"Time"}}</label>
                                      <input type="time" name="from" id="from" class="form-input time-range"  v-model="Tour.time_from" />
                                  </div>
                               </div>
                        </div>
                        <div class="form-col date-time-inputs">
                            <label class="label" for="agent">{{"leasing agent"}}</label>
                            <div v-if="agents">
                              <input type="text" class="form-input" name="agent" v-model="Tour.agent_name" />
                            </div>
                            <div v-else>
                              Not Available  
                            </div>
                           
                        </div>
                        <div class="form-col date-time-inputs">
                         <label class="label" for="units">{{"tour type"}}</label>
                          <dropdown :options="tourTypes" name="tourTypes" v-model="tourType"  :multiple="false" :objectMode="true" :searchable="true"></dropdown>
                       
                        </div>
                        <div class="form-col">
                           <label class="label" for="units">{{"unit(s)"}}</label>
                          <dropdown :options="vacantUnits" name="unit" v-model="Tour.units"  :multiple="true" :objectMode="false" :searchable="true"></dropdown>
                        </div>
                 </div>
                 <br />
                 <div>
                     <div>

                         <label class="label" for="description">{{"description"}}</label>
                         <textarea v-model="Tour.description" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancelEdit" class="btn-primary "  type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">  
                        <button @click="saveTour" class="btn-primary btn-solid " type="submit"> save</button>
                      </div>
                    </div>
             
                </div>
            </div>
            </Form>
            <div class="flex mt-4 spacer" v-else>
                   <div class="w-1/2 relative group">
                       <p class="label">date</p>
                       <p class="value">{{tour.display_showing_date}}</p>
                       <p class="label">time</p>
                       <p class="value">{{tour.display_time_from}}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">leasing consultant</p>
                       <p class="value">{{tour.agent_name? tour.agent_name :  "Not Available"  }}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">tour type</p>
                       <p class="value">{{tour.tour_type}}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">unit(s)</p>
                       <div class="value flex">
                          <p>
                            {{displayUnits}}
                          </p>
                       </div>
                   </div>
            </div>
            <br />
            <div v-if="!edit">
                <div class="spacer">
                     <p class="label">description</p>
                     <p class="value">  {{  tour.description}}</p>
                </div>
            </div>
            <br />
            <div v-if="tour.followup_action && tour.followup_date" class="flex spacer mb-3" >
                <div class="justify-left">
                    <div class="rounded-full white flex items-center custom-size">
                        <icon
                            name="checkcircle"
                            class="block  rounded-full bg-green-400 w-1 h-1  "
                        />
                    </div>
                </div>
                <div class="justify-right ">
                    <div class="label">
                      follow up
                    </div>
                    <div class="bold black-font">
                      {{tour.followup_action}} on {{tour.followup_date}}
                    </div>
                </div>
            </div>
            <AddFollowUp v-else  :tourIdProp="tour.tour_id" @addedFollowup="updateLocalData"></AddFollowUp>
        </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapActions} from "vuex";
import Icon from "@/components/ui/Icon";
import Form from "@/components/form/Form";
import Dropdown from "@/components/ui/Dropdown";
import AddFollowUp from "@/components/guest_cards/tours/AddFollowUp";
import {findValueByKey} from "@/utils/GuestCards";
export default {
    name:'TourCard',
    components:{Icon,AddFollowUp,Form,Dropdown},
    props:[
      'tour',
      'vacantUnits'
    ],
    data(){
        return {
            Tour:this.tour,
            edit: false,
            tourType:null,
            agents : null,
        }
    },
    mounted(){
       /*  this.updateLocalData();  */
    },
    computed: {
      ...mapGetters({
          getUnits: 'guest_cards/getUnits'
        }),
        tourTypes(){
          return [
            {
              key:0,
              value: "Virtual"
            },
            {
              key:1,
              value: 'In-person'
            },
            {
              key:3,
              value: 'Media'
            },
          
          ]
        },
        displayUnits(){
            let return_display = null;

            if(this.Tour.units){
              this.Tour.units.forEach((unit,index) => {
                  return_display = findValueByKey(this.getUnits,unit) + ((index + 1) ==  this.Tour.units.length ? " " : ", ")
              })
            }
            return return_display
        },
    },
    methods: {
        ...mapActions({
        notifySuccess: 'alerts/notifySuccess',
        notifyError: 'alerts/notifyError',
        }),
        saveTour(){
            this.Tour.guest_card_id = this.uuid,
            this.Tour.units = JSON.stringify(this.tour.units)
            this.Tour.type  = JSON.stringify(this.tour.type)

            let params = {
                id : this.Tour.tour_id,
                data : {
                    tour_id     : this.Tour.tour_id,
                    description : this.Tour.description,
                    agent_name  : this.Tour.agent_name,
                    showing_date: this.Tour.showing_date,
                    units       : JSON.parse(this.Tour.units),
                    tour_type   : this.tourType,
                    time_from   : this.Tour.time_from,
                    time_to     : this.Tour.time_to
                }
            }

            this.$guestCardDataProvider.update('tours',params).then((response) => {
                console.log(response)
              if(response.success){
                  this.notifySuccess(response.message)
                  this.edit = false
              }else{
                  this.notifyError(response.message)
              }
                 
            }).catch()
       
        },
        updateLocalData(){
             this.$guestCardDataProvider.getOne('tours',{id:this.tour.tour_id,tour_id:this.tour.tour_id }).then((response) => {
                console.log(response)
                  if(response.success){
                      this.Tour = {
                          tour_id       : response.data.tour_id,
                          description   : response.data.description,
                          agent_name    : response.data.agent_name,
                          units         : JSON.parse(response.data.unit_information),
                          tour_type     : response.data.tour_type,
                          time_from     : moment(response.data.tour_time_from).format('LT'),
                          time_to       : moment(response.data.tour_time_to).format('LT'),
                          showing_date  : moment(response.data.showing_date).format('MM/DD/YY')
                      }
                  }else{
                      this.notifyError(response.message)
                  }
             })
        },
        cancelEdit(){
            this.edit=false
        },
        editMode(){
            this.tourTypes.forEach(type => {
               
                    if(type.value == this.Tour.tour_type){
                        this.tourType = type
                    }
              
            });
            this.edit = true
        },
    },
    created() {
    }
}
</script>
<style scoped>
    .label{
        padding-left:0px;
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .black-font{
        font-size: 12px;
        letter-spacing: 0.72px;
        font-weight: 600;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
     .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
    .custom-size{
      height: 30px !important;
      width: 30px !important;
    }
    .white{
      color: white;
    }
    .bold{
      font-weight: 800;
    }
     .date-time-inputs{
      margin-right: 0px !important;
    }
     .time-range{
      width: 47.5% !important;
    }
    .card{
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
    }
    .spacer{
        padding-left:  30px !important;
        padding-right: 30px !important;
    }
</style>
