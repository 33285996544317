<template>
    <div v-if="pets?.length && !showPetInfoForm">
        <!-- pets list -->
        <div>
            <list
                :if="columns && pets"
                :columns="columns"
                :items="pets"
                :css="{ tableClass: 'table table--striped', bodyRowClass: 'row' }"

            >
                <template v-slot:column:is_service="{value}">
                    {{ value ? "yes" : "no" }}
                </template>
                <!-- <template v-slot:column:pet_id="{value}">
                    <icon
                        name="pencil_{value}"
                        class="w-4 h-4 block absolute right-2 top-1 opacity-0 cursor-pointer group-hover:opacity-50"
                        @click="load(value)"
                    />
                </template> -->
            </list>
        </div>


    </div>
</template>

<script>
import List from "@/components/list/List";
import {birdsOptions, catsOptions, dogsOptions, miscPetsOptions, petTypeOptions} from "@/utils/GuestCards";

export default {
    components: {
        List,
    },
    props: {
        pets: {
            type: Array,
            required: true,
            default: () => { return [] }
        },
        // items: [],

    },
    data: () => {
        return {
            petInfo: {
                name: null,
                breed: null,
                type: null,
            },
            typeOptions: petTypeOptions,
            breedOptions: [],
            breeds: {
                Dogs: dogsOptions,
                Cats: catsOptions,
                Birds: birdsOptions,
                Miscellaneous: miscPetsOptions,
            },
            showPetInfoForm: false,
            columns: [
                {
                    name: 'name',
                    title: 'name',
                    class: 'w-5/12',
                    sortable: false
                },
                {
                    name: 'type',
                    title: 'type',
                    class: 'w-2/12',
                    sortable: false
                },
                {
                    name: 'breed',
                    title: 'breed',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'is_service',
                    title: 'service animal',
                    class: 'w-2/12 text-center',
                    sortable: false
                },
                // {
                //     name: 'pet_id',
                //     title: '',
                //     class: 'w-1/12',
                //     sortable: false,
                // }
            ],
        }
    },
    watch: {
        // 'petInfo.type': {
        //     handler: function() {
        //         if(this.petInfo.type)
        //             this.breedOptions = this.breeds[this.petInfo.type];
        //         else {
        //             this.breedOptions = [];
        //             this.petInfo.breed = null;
        //         }
        //     },
        //     immediate: true
        // }
    },
    methods: {
        // ...mapActions({
        //     updateGuestCardInStore: 'guest_cards/updateGuestCard'
        // }),
        // load(pet) {
        //     this.petInfo = pet.petInfo;
        //     this.showPetInfoForm = true;
        // },
        // cancel() {
        //     this.close();
        // },
        // edit() {
        //     if( this.validator.passes() ) {
        //         this.updateGuestCardInStore({
        //             guestCardId: this.guestCard.id,
        //             data: {
        //                 pets: this.guestCard.pets,
        //             }
        //         });
        //         this.$emit('pet-edited');
        //         this.close();
        //     }
        // },
        // close() {
        //     this.showPetInfoForm = false;
        // }
    },
    // validations: {
    //     'petInfo.name': 'required',
    //     'petInfo.breed': 'required',
    //     'petInfo.type': 'required',
    // },
    created() {
        // this.initValidator();
    }
}
</script>
