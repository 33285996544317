<template>
    <div v-if="coresidents">
        <!-- co-residents list -->
        <div class="container">
            <list
                :if="columns && coresidents"
                :columns="columns"
                :items="coresidents"
            >
                <template v-slot:row="{ item: { first_name, last_name, phone, email, contact_preference } }">
                    <div class="column w-3/12">{{ first_name }} {{ last_name }}</div>
                    <div class="column w-3/12">{{ phone?.phone_number }}</div>
                    <div class="column w-3/12 break-words">{{ email?.email_address }}</div>
                    <div class="column w-3/12 text-center">{{ contactPreferences(contact_preference) }}</div>
                </template>
            </list>
            <!-- <icon
                name="pencil2"
                class="w-4 h-4 block absolute right-2 top-1 opacity-0 cursor-pointer group-hover:opacity-50"
                @click="load(associatedVisitor)"
            /> -->
        </div>
    </div>
</template>

<script>
// import { mapActions } from "vuex";
// import Icon from "@/components/ui/Icon";
import List from "@/components/list/List";

export default {
    components: {
        // Icon,
        List
    },
    computed: {
        // contactPreferences(item) {
        //     let contactPreference = item.contact_preference;
        //     let contacts = [];
        //     if(contactPreference.email)
        //         contacts.push('email');

        //     if(contactPreference.sms)
        //         contacts.push('sms');

        //     if(contactPreference.phone)
        //         contacts.push('phone');

        //     return contacts.join(', ');
        // },
    },
    props: {
        coresidents: {
            type: Array,
            required: true,
            default: () => { return [] }
        },
    },
    data: () => {
        return {
            showAssociatedVisitorInfoForm: false,
            columns: [
                {
                    name: 'name',
                    title: 'name',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'phone',
                    title: 'phone',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'email',
                    title: 'email',
                    class: 'w-3/12',
                    sortable: false
                },
                {
                    name: 'contact_preferences',
                    title: 'contact preferences',
                    class: 'w-3/12 text-center',
                    sortable: false
                },
            ],
        }
    },
    methods: {
        contactPreferences(item) {
            let contactPreference = JSON.parse(item);
            let contacts = [];
            if(contactPreference.email)
                contacts.push('email');

            if(contactPreference.sms)
                contacts.push('sms');

            if(contactPreference.phone)
                contacts.push('phone');

            return contacts.join(', ');
        },
    },
}
</script>
<style type="scoped">
    .container .table {
        display: block
    }
</style>