<template>
  <div >
       <div :class="'w-1/8  p-4 border h-full overflow-x relative group card' " >
                <icon
                    name="edit"
                    class="w-4 h-4 block absolute right-2 opacity-0 cursor-pointer group-hover:opacity-50"
                    @click="editMode"
                />
            <loader :loading="loading" :backdrop="true"/>
            <Form :submit="saveNote" v-if="edit">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" >
               
            <div class="p-4 mt-1 mb-3 " >
                 <div>
                     <div>

                         <label for="description">{{"description"}}</label>
                         <textarea v-model="Note.content" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancelEdit" class="btn-primary "  type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">
                          <input type="submit" class="btn-primary btn-solid " value="save">
                      </div>
                    </div>
             
                </div>
            </div>
            </Form>
            <div  v-else>
                   <div >
                       <span class="agent">{{agentName ? agentName : ''}} </span><span class="value">{{noteDate}}</span> 
                     
                       
                   </div>
                  
                   <!-- <div class="w-1/2 relative group">
                       <p class="label">unit(s)</p>
                       <div class="value flex">
                            <p v-for="(value,index) in tour.units" :key="index">
                            {{ value + ((index + 1) == tour.units.length ? " " : ", ")}}
                            </p>
                       </div>
                   </div> -->
            </div>
            <br />
            <div v-if="!edit">
                <div>
                     <p class="value content"><pre>{{  Note ? Note.content : ''}}</pre></p>
                </div>
            </div>
            <br />
             
             
            <br />
        </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions,mapGetters} from "vuex";
import Icon from "@/components/ui/Icon";
import Form from "@/components/form/Form";
import Loader from '@/components/ui/Loader';

export default {
    name:'NoteCard',
    components:{Icon,Form,Loader},
    props:['noteData'],
    data(){
        return {
            Note:this.noteData,
            uuid: null,
            edit: false,
            units:[],
            tourType:null,
            dummyUser:[{
              key : "35155295-4c82-4dc8-8878-033971364b3d",
              value: "John Paul Perez"
            }],
            loading:false
        }
    },
    mounted(){
        this.uuid = this.getGuestCardUUID;
    },
    computed: {
        ...mapGetters({
            getGuestCardUUID: 'guest_cards/getGuestCardUUID'
        }),
        agentName(){
            return  this.Note ? (this.dummyUser.find( ({ key }) => key === this.Note.created_by_user_id ) ? this.dummyUser.find( ({ key }) => key === this.Note.created_by_user_id ).value : '') : '';
        },
        noteDate(){
            return moment(this.Note ? this.Note.created_at : null).format("MM/DD/YY")
        }
    },  
    watch: {
        noteData:function(newValue) {
               this.Note = newValue
            
        } 
    },
    methods: {
        ...mapActions({
        notifySuccess: 'alerts/notifySuccess',
        notifyError: 'alerts/notifyError',
        }),
        saveNote(){
            this.loading = true
                this.Note.guest_card_id = this.uuid

                let params = {
                    id : this.Note.note_id,
                    data : {
                        note_id     : this.Note.note_id,
                        content     : this.Note.content,
                      
                    }
                }

                this.$guestCardDataProvider.update('notes',params).then((response) => {
                    console.log(response)
                  if(response.success){
                      this.notifySuccess(response.message)
                      this.loading = false
                      this.edit = false
                  }else{
                      this.notifyError(response.message)
                  }

                }).catch()
        },
        cancelEdit(){
            this.edit=false
        },
        editMode(){
        
            this.edit = true
        },
    },
    created() {
      /*   this.guestCards = this.getGuestCards; */
    }
}
</script>
<style scoped>
    .agent{
        color:black;
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 800;
        padding-right: 30px;
        position: relative;
        float : left;
    }
    .card{
      padding : 20px;
    }
    .value{
        font: normal normal normal 14px/20px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
         width: 200px;
         color: #656565;
    }
    .content{
        color:black !important;
        width: 100% ;
        font-weight: 100;
    }
     .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
</style>