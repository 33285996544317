<template >
    <Form
          ref="form"
          :submit="save"
          :initialValues="null"
        >
    <div class="add-follow-up-container ">
        <span class="flex items-center text-blue-500 text-sm cursor-pointer select-none spacer mb-3" @click="isOpen = !isOpen">
                <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
                <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
                <span class="mb-1">Add follow up</span>
            </span>
        <div class="bg-gray-100 p-4 border mt-1  " v-if="isOpen">
            <div class="flex spacer">
                <div class="w-1/2 ">
                   <div class="w-1/8 checkbox-group-container">
                        <CheckGroupBoxHorizontal
                        name="contactPreferrences"
                        v-model="followUpType"
                        label="follow up type"
                        styling="showing"
                        :options='[{"key":"email","value":"email","checked":true},{"key":"sms","value":"sms","checked":false},{"key":"phone","value":"phone","checked":false}]'
                        editMode
                        />          
                    </div> 
                </div>
                <div class="w-1/2 ">     
                    <div class="w-1/2">
                        <div class="form-col">
                            <form-label>date of follow up</form-label>
                            <input type="date" id="from-date" v-model="followUpDate"  class="form-input" />
                           <!--  <span v-if="hasError('apartment.moveInStartDate')" class="form-error">{{ showError('apartment.moveInStartDate') }}</span> -->
                        </div>
                       
                    </div>      
                </div>
            </div>
                <div class="mt-4 text-right">
                    <button class="btn-primary ml-2 pull-right" @click="close">cancel</button>
                    <button class="btn-primary btn-solid ml-2" @click="save">save</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
import Form from "@/components/form/Form";
import Icon from "@/components/ui/Icon";
import CheckGroupBoxHorizontal from "@/components/guest_cards/common/CheckGroupBoxHorizontal";

export default {
    name:"AddFollowUp",
    components:{Icon,CheckGroupBoxHorizontal,Form},
    props:['tourIdProp'],
    data(){
        return {
            isOpen:false,
            followUpType: null,
            followUpDate: null,
            followupTime:{
                from: null,
                to: null
            },
            tour_id : this.tourIdProp
        }
    },
    emits: ['addedFollowup'],
    methods: {
        save(){
         /*    console.log(this.followUpType) */
            let payload = {
                    action : this.followUpType.map(({ key }) => key),
                    date   : this.followUpDate,
                    tour_id: this.tour_id
            }   
            this.$guestCardDataProvider.update('addFollowUp',{id: this.tour_id, ...payload}).then((response) => {
                if(response.success){
                   this.$emit('addedFollowup',payload)
                }
            })
            return payload
        }
    }
}
</script>
<style scoped>
.pull-right{
    position: relative;
    float: left;
}
 .spacer{
        padding-left:  30px !important;
        padding-right: 30px !important;
    }
</style>
<style type="text/css">
    .checkbox-group-container div ul.group-checkbox li {
        input {
            float: left;
            margin-top: 3px;
        }
        label {
            display: inline;
            padding-left:4px;
        }
    }
</style>