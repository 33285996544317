<template>
    <div class="overflow-y-auto">
        <ul id="example-1">
            <li v-for="concession in concessions" :key="concession.concession_id">
                <collapsible v-if="concession.content.length > maxHeaderString">
                    <template v-slot:heading>
                        {{concession.content.substr(0, maxHeaderString)}}
                        {{concession.content.length > maxHeaderString ? '...' : ''}}
                    </template>
                    <template v-slot:content>
                        {{concession.content}}
                    </template>
                </collapsible>
                <div class="w-full border border-transparent hover:bg-blue-200 p-4" v-else>
                        {{concession.content}}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
// import { mapActions } from "vuex";
// import Icon from "@/components/ui/Icon";
import Collapsible from "@/components/ui/Collapsible";

export default {
    components: {
        // Icon,
        Collapsible
    },
    props: {
        concessions: {
            type: Array,
            required: true,
            default: () => { return [] }
        }
    },
    data: () => {
        return {
            maxHeaderString: 50,
        }
    },
    // methods: {
    //     ...mapActions({
    //         updateGuestCardInStore: 'guest_cards/updateGuestCard'
    // },
}
</script>