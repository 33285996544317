<template>
  <div class="card">
      <loader :loading="loading" :backdrop="true"/>
      <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
          Tours
           <span class="flex justify-right text-blue-500 text-sm cursor-pointer select-none float-right font-light" @click="isOpen = !isOpen">
                      <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
                      <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
                      <span class="mb-1">Add a tour</span>
          </span>
      </p>
       <Form :submit="saveTour">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" v-if="isOpen">
               
            <div class="p-4 mt-1 mb-3 " >
            
                 
                 <div class="flex mt-4">
                        <div class="form-col date-time-inputs">
                               <label for="date">{{"date"}}</label>
                               <input type="date" name="date" class="form-input"  :min="currentDate" v-model="tour.date" /> 
                        </div>
                        
                        <div class="form-col  date-time-inputs">
                            <label for="agent">{{"leasing agent"}}</label>
                            <input type="text" class="form-input" name="agent" v-model="tour.agent_display_name" />
                        </div>
                        <div class="form-col date-time-inputs">
                         <label for="units">{{"tour type"}}</label>
                          <dropdown :options="tourTypes" name="tourTypes" v-model="tour.type"  :multiple="false" :objectMode="true" :searchable="true"></dropdown>
                       
                        </div>
                        <div class="form-col">
                           <label for="units">{{"unit(s)"}}</label>
                          <dropdown :options="unitOptions" name="unit" v-model="tour.units"  :multiple="true"  :searchable="true"></dropdown>
                        </div>
                 </div>
                 <div class="flex mt-4 w-1/2">
                      <div class="form-col ">
                          <label for="from">{{"Time"}}</label>
                          <input type="time" name="from" id="from" class="form-input time-range"  v-model="tour.timeFrom" />
                      </div>
                 </div>
                 <br />
                 <div>
                     <div>

                         <label for="description">{{"description"}}</label>
                         <textarea v-model="tour.description" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancel" class="btn-primary " type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">  
                        <button @click="saveTour" class="btn-primary btn-solid " type="submit"> save</button>
                      </div>
                    </div>
                </div>
            </div>
        </Form>
  </div> 
</template>

<script>
import Loader from '@/components/ui/Loader';
import { mapGetters} from "vuex";
import Form from "@/components/form/Form";
import Icon from "@/components/ui/Icon";
import moment from "moment";
import Dropdown from "@/components/ui/Dropdown";
export default {
    name:'AddTourCard',
    components:{Icon,Form,Dropdown,Loader},
    data(){
        return {
            uuid: null,
            tour:{
              guest_card_id:null,
              agent_display_name : 'Temporary Agent',
              date: moment().format('YYYY-MM-DD'),
              units:[],
              type:null,
              description: null,
              timeFrom:null,
            },
            units:[],
            isOpen:false,
            oldTimeFrom: null,
            timeLimit:null,
            loading:false,
        }
    },
    mounted(){
      
    },
    emits: ['new-data'],
    watch:{
            tour:{
              handler:function(value){

                if(value.timeFrom){
                   this.timeLimit =  moment(value.timeFrom).format('HH:mm:ss').split(" ")[0]
                }
              }
            },
            deep:true
    },
    computed: {
        ...mapGetters({
          getGuestCardUUID: 'guest_cards/getGuestCardUUID'
        }),
        currentDate(){
          return moment().format('YYYY-MM-DD');
        },
        unitOptions(){
          let list = [];
            this.units.forEach((item)=>{
                console.log(item.units)
              list =  list.concat(item.units)
            })  
          return list
        },
        tourTypes(){
          return [
            {
              key:0,
              value: "Virtual"
            },
            {
              key:1,
              value: 'In-person'
            },
            {
              key:3,
              value: 'Media'
            },
          
          ]
        }
     
    },
    methods: {
        loadTours(){
           
        },
        saveTour(){
            this.tour.guest_card_id = this.uuid,
            this.tour.units = JSON.stringify(this.tour.units)
            this.tour.type  = JSON.stringify(this.tour.type)

            this.$guestCardDataProvider.create('tours',this.tour).then((response) => {
                if(response){
                   this.$emit('new-data',true)
                   this.resetTour()
                }
                 
            }).catch()
            this.isOpen = false
        },
        resetTour(){
             this.tour = {
              agent_display_name : 'Temporary Agent',
              showing_date: moment().format('YYYY-MM-DD'),
              units:[],
              tour_type:null,
              description: null
            }
        },
        cancel(){
            this.resetTour()
            this.isOpen = false
        }
    },
    created() {
        this.uuid = this.getGuestCardUUID;
        this.laoding = true
        this.$guestCardDataProvider
                .get('getAvailableUnits')
                .then((response) => {
                 this.units = response;
                 this.laoding = false
                })
                .catch((err) => {
                  console.log(err)
                  this.laoding = false
                })
    }
}
</script>
<style scoped>
    .label{
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
    .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
    .date-time-inputs{
      margin-right: 0px !important;
    }
    .time-range{
      width: 47.5% !important;
    }
</style>