<template>
    <div class="h-5-6 pos-rel">
        <!-- price_quote form -->
        <div v-if="price_quoted.length" class="h-1-2-auto overflow-y-auto">
            <div v-for="(price_quote, index) in price_quoted" :key="'price_quote-' + index">
                <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                    <div @click="editContent(price_quote.price_quote_id)">
                        {{price_quote.unit}} - ${{price_quote.price}}({{price_quote.duration}}hours)
                    </div>
                    <div>
                        <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="remove(price_quote.price_quote_id)"></icon>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            No price quoted yet
        </div>
        <div class="w-full bg-gray-100 p-4 border mt-1 h-form">
            <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                Price quotation
            </p>
            <div class="form-row">
                <div class="form-col">
                    <label for="quote_unit">unit(to be implemented)</label>
                    <input type="text" class="form-input" id="quote_unit" v-model="newQuote.unit" />
                </div>
                <div class="form-col">
                    <label for="quote_price">price</label>
                    <input type="text" class="form-input" id="quote_price" v-model="newQuote.price" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="quote_duration">duration</label>
                    <input type="text" class="form-input" id="quote_duration" v-model="newQuote.duration" />
                </div>
                <div class="form-col">
                    <label for="quote_month">applicable for month</label>
                    <input type="text" class="form-input" id="quote_month" v-model="newQuote.month" />
                </div>
            </div>

            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="clearAdd">clear form</button>
                <button class="btn-primary ml-2" @click="addQuotation">{{newQuote.price_quote_id ? 'update quotation' : 'add'}}</button>
            </div>
        </div>
        <div class="text-right mt-4 footer">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="update">Update price quotation</button>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import {v4} from "uuid";
import Icon from "@/components/ui/Icon";

const defaultQuotation = {
        unit: '',
        price: '',
        duration: '',
        month: '',
    };

export default {
    components: {
        Icon,
    },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            price_quoted: [],
            newQuote: {...defaultQuotation},
            maxHeaderString: 50,
            toRemove: [],
            toAdd: [],
            toUpdate: {},
        }
    },
    emits: ['price-quoted-edited'],
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        remove(price_quote_id) {
            let searchIndex = -1;
            let searchedQuotation = this.price_quoted.find((item, index) => {
                let found = item.price_quote_id === price_quote_id;
                if (found) {
                    searchIndex = index;
                    return true;
                }
                return false;
            });
            if (confirm("Are you sure you want to remove this quotation?")) {
                this.price_quoted.splice(searchIndex, 1);
                this.toRemove.push(price_quote_id);
                console.log("removing", searchedQuotation);
                console.log("new price_quoted", this.price_quoted);
            }
        },
        clearAdd() {
            this.newQuote = {...defaultQuotation};
        },
        addQuotation() {
            let quotationIndex = -1;
            if(this.newQuote.price_quote_id) {
                quotationIndex = this.price_quoted.findIndex(item => item.price_quote_id === this.newQuote.price_quote_id);
                let newData = {
                    ...this.newQuote,
                };
                this.price_quoted[quotationIndex] = newData;
                this.toUpdate[this.newQuote.price_quote_id] = newData;
            } else {
                let id = v4();
                let quotationToAdd = {
                    ...this.newQuote,
                    price_quote_id: id,
                }
                this.price_quoted.push(quotationToAdd);
                this.toAdd.push(quotationToAdd);
            }
            this.clearAdd();
        },
        close() {
            this.price_quoted = JSON.parse(JSON.stringify(this.guestCard.price_quoted));
            this.$emit('price-quoted-edited');
            this.toRemove = [];
            this.toAdd = [];
            this.toUpdate = [];
            this.isEditing = false;
        },
        update() {
            this.isEditing = true;
            let dataToUpdate = {
                price_quoted: JSON.parse(JSON.stringify(this.price_quoted)),
            },
            updateRequest = {
                price_quoted: {
                    to_add: this.toAdd,
                    to_delete: this.toRemove,
                    to_modify: this.toUpdate
                },
            };
            this.$guestCardDataProvider
                .update('guestCardDetail', {
                    guestCardId: this.$route.params.guestCardId,
                    data: updateRequest,
                })
            this.updateGuestCardInStore(dataToUpdate);
            this.close();
        },
        editContent(id) {
            let searchedQuotation = this.price_quoted.find(item => item.price_quote_id === id);
            this.newQuote = {...searchedQuotation};
        },
    },
    created() {
        this.price_quoted = JSON.parse(JSON.stringify(this.guestCard.price_quoted || ""));
    }
}
</script>
<style scoped>
    .footer {
        position: initial;
        right: 0;
        bottom: -2rem;
    }
    .h-1-2-auto {
        max-height: 63%;
    }

    .h-full {
        height: 100%;
    }
    .pos-rel {
        position: relative;
    }

    .h-form {
        /*height: 34%;*/
        margin-bottom: 0px;
        padding-bottom: 10px;
        padding-top: 0.5rem;
    }
</style>