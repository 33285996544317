<template>
  <FinalField :name="name">
    <template v-slot="props">
        <div>
            <label class="group-checkbox-label">{{label}}</label>
            <ul class="group-checkbox">
            <li  v-for="(option,index) in options"
                :key="option.key"
                :class="['new-checkbox', !index  ? 'no-margin' : '' ]" >
                <input 
                    :id="option.key+name+index"
                    class="new-input"
                    v-if="editMode" 
                    :name="option.key"  
                    type="checkbox"
                    v-model="checkedOptions"
                    :value="option.key"
                    :checked="option.checked"
                    > 
                <label  :for="option.key+name+index">
                    <icon
                        v-if="!editMode"
                        class="inline"
                        :name="props.value && props.value.includes(option.key) ? 'ok' : 'cancel'"
                    />
                    {{option.value}}
                </label>
                
            </li>
            </ul>

       
        </div>

    <!--   <div class="form-col">
        <label>{{label}}</label>
        
        <label
          v-for="option in options"
          :key="option.key"
          class="checkbox-label"
          :class="{'cursor-pointer': editMode}"
        >
          <input
            v-if="editMode"
            type="checkbox"
            :name="props.name"
            :checked="(props.value || []).includes(option.key)"
            @change="handleChange(props, option.key)"
          >
          <icon
            v-if="!editMode"
            class="inline"
            :name="props.value && props.value.includes(option.key) ? 'ok' : 'cancel'"
          />
          {{option.value}}
        </label>
      </div> -->
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
/*   import Icon from '@/components/ui/Icon'; */

  export default {
    name: "CheckGroupBoxHorizontal",
    components: {
      FinalField,
     /*  Icon, */
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      styling: {
        type: String,
        required: false,
      },
    },  
    data(){
        return{
            checkedOptions:[]
        }
    },
    emits: ['input'],
    mounted() {
      console.log("mounted value => ",this.options.find(item => item.checked))
      this.checkedOptions.push(this.options.find(item => item.checked).key)
    },
    watch:{
        checkedOptions: function(newVal){
             let arr = [] 
             
             newVal.forEach(id => {
                   arr.push(this.options.find( ({ key }) => key === id ))
             });
            

             this.$emit('input', arr)
        }
    }
    
  }
</script>

<style scoped>
    .form-col label.checkbox-label {
    font: inherit;
    }
    .new-checkbox{
    display: inline-block;
    margin-left: 30px;
    }
    .group-checkbox-label{  
        font-weight: 800;
    }
    .group-checkbox{
     margin-top: 10px;
    }
    .no-margin{
        margin-left: 0px !important;
    }

    .new-input > [type="checkbox"]:not(:checked):focus + label:before {
        border: 1px solid #66afe9;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
</style>
