<template>
    <div class="h-full flex flex-1" v-if="!loading && guestCard">
        <!-- Start of side nav -->
        <!-- TODO: create separate component where you can use constants for side nav -->
        <div class="sidenav overflow-auto h-full">
            <div class="overflow-auto">
                <div @click="showDiv('personalInfoDetails')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'personalInfoDetails' ? 'active' : '']"
                    >Personal Information</label
                    >
                </div>
                <div @click="showDiv('apartmentInfoDetails')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'apartmentInfoDetails' ? 'active' : '']"
                    >Apartment Information</label
                    >
                </div>
                <div @click="showDiv('petsDetails')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'petsDetails' ? 'active' : '']"
                    >Pets</label
                    >
                </div>
                <div @click="showDiv('coResidentsDetails')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'coResidentsDetails' ? 'active' : '']"
                    >Co-Residents</label
                    >
                </div>
                <div @click="showDiv('concessionDetails')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'concessionDetails' ? 'active' : '']"
                    >Prices and Concessions offered</label
                    >
                </div>
                <div @click="showDiv('tours')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'tours' ? 'active' : '']"
                    >Tours</label
                    >
                </div>
                <div @click="showDiv('tasks')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'tasks' ? 'active' : '']"
                    >Tasks</label
                    >
                </div>
                <div @click="showDiv('notes')">
                    <label
                    class="form-section-title"
                    v-bind:class="[activeDiv === 'notes' ? 'active' : '']"
                    >Notes</label
                    >
                </div>
          </div>
        </div>
        <!-- End of side nav -->
        <!-- Details container -->
        <div
            v-bind:ref="containerId"
            class="w-2/3 form-col h-full detailsContainer" v-if="!loading"
            :class="[activeDiv === 'concessionDetails' ? 'overflow-initial' : 'overflow-y-auto']"
        >
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div 
                    ref="personalInfoDetails"
                    v-show="activeDiv === 'personalInfoDetails'"
                >
                    <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                        Personal Information
                        <span class="icon-container" v-show="!isEditting.personalInfo" @click="setEdit('personalInfo', true)">
                            <icon name="edit" class="2-3 mr-1"/>
                        </span>
                    </p>
                    <show-personal-info v-if="!isEditting.personalInfo && guestCard.fetched" :guest-card="guestCard" />
                    <edit-personal-info @personal-info-edited="setEdit('personalInfo', !isEditting.personalInfo)" v-if="isContactFetched && guestCard.fetched" :guestCard="guestCard" v-show="isEditting.personalInfo" />
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="apartmentInfoDetails" v-show="activeDiv === 'apartmentInfoDetails'">
                    <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                        Apartment Information
                        <span class="icon-container" v-show="!isEditting.apartmentInfoDetails" @click="setEdit('apartmentInfoDetails', true)">
                            <icon name="edit" class="2-3 mr-1"/>
                        </span>
                    </p>
                    <show-apartment-info v-if="!isEditting.apartmentInfoDetails && guestCard.fetched" :apartment="guestCard.apartment" />
                    <edit-apartment-info @apartment-info-edited="setEdit('apartmentInfoDetails', !isEditting.apartmentInfoDetails)" v-if="guestCard.fetched" :guestCard="guestCard" v-show="isEditting.apartmentInfoDetails" />
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="petsDetails" v-show="activeDiv === 'petsDetails'">
                    <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                        Pets
                        <span class="icon-container multi-icon flex items-center text-blue-500 text-sm cursor-pointer select-none font-light" v-show="!isEditting.petsDetails" @click="setEdit('petsDetails', true)">
                            <icon name="plus" class="w-4 h-4 inline-block mr-1" />
                            <span class="mb-1">Add a pet</span>
                        </span>
                    </p>
                    <pets-details v-if="!isEditting.petsDetails && guestCard.fetched" :pets="guestCard.pets" />
                    <edit-pets @pets-edited="setEdit('petsDetails', !isEditting.petsDetails)" v-if="guestCard.fetched" :guestCard="guestCard" v-show="isEditting.petsDetails" />
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="coResidentsDetails" v-show="activeDiv === 'coResidentsDetails'">
                    <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                        Co-residents
                        <span class="flex justify-right text-blue-500 text-sm cursor-pointer select-none float-right font-light" v-show="!isEditting.coResidentsDetails" @click="setEdit('coResidentsDetails', true)">
                            <icon name="plus" class="w-4 h-4 inline-block mr-1"/> Add Co-resident
                        </span>
                    </p>
                    <loader v-if="!isCoresidentsContactFetched" :loading="!isCoresidentsContactFetched" :backdrop="false" />
                    <co-residents-details v-if="!isEditting.coResidentsDetails && isCoresidentsContactFetched && guestCard.fetched" :coresidents="guestCard.coresidents" />
                    <edit-co-residents v-if="isCoresidentsContactFetched && guestCard.fetched" @co-residents-edited="setEdit('coResidentsDetails', !isEditting.coResidentsDetails)" :guestCard="guestCard" v-show="isEditting.coResidentsDetails" />
                </div>
            </Transition>
            <Transition name="slide-up" class="slide-up">
                <div class="h-full" ref="concessionDetails" v-show="activeDiv === 'concessionDetails'">
                    <div
                        class="w-full form-col overflow-y-auto h-1/2"
                        @mouseover="isFocusPrice = true" 
                        @mouseout="isFocusPrice = false"
                        :class="{focus: isFocusPrice}"
                    >
                        <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                            prices quoted
                            <span class="icon-container multi-icon" v-show="!isEditting.priceQuoted" @click="setEdit('priceQuoted', true)">
                                <icon name="edit" class="2-3 mr-1"/>
                            </span>
                        </p>
                        <price-quoted v-show="!isEditting.priceQuoted" v-if="guestCard.fetched" :prices="guestCard.price_quoted" />
                        <edit-price-quoted @price-quoted-edited="setEdit('priceQuoted', !isEditting.priceQuoted)" :guestCard="guestCard" v-if="guestCard.fetched" v-show="isEditting.priceQuoted" />
                    </div>
                    <div
                        class="w-full form-col h-1/2 no-margin"
                        @mouseover="isFocusConcession = true" 
                        @mouseout="isFocusConcession = false"
                        :class="{focus: isFocusConcession}"
                    >
                        <p class="font-bold text-sm text-black py-1 border-b border-gray-200 pos-rel">
                            specials or concessions offered
                            <span class="icon-container multi-icon" v-show="!isEditting.concessionDetails" @click="setEdit('concessionDetails', true)">
                                <icon name="edit" class="2-3 mr-1"/>
                            </span>
                        </p>
                        <concessions v-show="!isEditting.concessionDetails" v-if="guestCard.fetched" :concessions="guestCard.concessions" />
                        <edit-concessions @concessions-edited="setEdit('concessionDetails', !isEditting.concessionDetails)" v-if="guestCard.fetched" :guestCard="guestCard" v-show="isEditting.concessionDetails" />
                    </div>
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="tours" v-show="activeDiv === 'tours'">
                    <!-- TODO: make it the same as other detail editing -->
                    <tours-list :tours="tours" />
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="tasks" v-show="activeDiv === 'tasks'">
                    <AddTaskCard @taskCreated="loadTasks" ></AddTaskCard>
                    <TasksList @reload-tasks="loadTasks" :tasks="tasks" ></TasksList>
                </div>
            </Transition>
            <Transition
                name="slide-up"
                class="slide-up h-5-6"
                @mouseover="isFocus = true" 
                @mouseout="isFocus = false"
                :class="{focus: isFocus}"
            >
                <div ref="notes" v-show="activeDiv === 'notes'">
                    <AddNoteCard @reload-data="reloadNotes" ></AddNoteCard>
                    <NotesList ref="notesList"></NotesList>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";

import ShowPersonalInfo from "@/components/guest_cards/show/ShowPersonalInfo";
import EditPersonalInfo from "@/components/guest_cards/show/EditPersonalInfo";
import ShowApartmentInfo from "@/components/guest_cards/show/ShowApartmentInfo";
import EditApartmentInfo from "@/components/guest_cards/show/EditApartmentInfo";
import PetsDetails from "@/components/guest_cards/show/PetsDetails";
import EditPets from "@/components/guest_cards/show/EditPets";
import CoResidentsDetails from "@/components/guest_cards/show/CoResidentsDetails";
import EditCoResidents from "@/components/guest_cards/show/EditCoResidents";
import Concessions from "@/components/guest_cards/show/Concessions";
import EditConcessions from "@/components/guest_cards/show/EditConcessions";
import PriceQuoted from "@/components/guest_cards/show/PriceQuoted";
import EditPriceQuoted from "@/components/guest_cards/show/EditPriceQuoted";
import Icon from "@/components/ui/Icon";
import ToursList from "@/components/guest_cards/tours/List";
import TasksList from "@/components/guest_cards/tasks/TasksList";
import AddTaskCard from "@/components/guest_cards/tasks/AddTaskCard";
import NotesList from "@/components/guest_cards/notes/NotesList";
import AddNoteCard from "@/components/guest_cards/notes/AddNoteCard";


export default {
    components: {
        ShowPersonalInfo,
        EditPersonalInfo,
        ShowApartmentInfo,
        EditApartmentInfo,
        PetsDetails,
        EditPets,
        CoResidentsDetails,
        EditCoResidents,
        Concessions,
        EditConcessions,
        PriceQuoted,
        EditPriceQuoted,
        Icon,
        ToursList,
        TasksList,
        AddTaskCard,
        NotesList,
        AddNoteCard,
        Loader,
    },
    mixins: [ ModalNavigation ],
    data: () => { 
        return {
            guestCard: {
                fetched: false
            },
            edit: false,
            uuid: null,
            loading: true,
            containerId: 'detailsContainer',
            activeDiv: 'personalInfoDetails',
            isFocus: false,
            isEditting: {
                personalInfo: false,
            },
            isFocusConcession: false,
            isFocusPrice: false,
            tasks: {
                open: [],
                closed: [],
            },
            isContactFetched: false,
            isCoresidentsContactFetched: false,
        }
    },
    watch: {
        guestCard() {
            this.getContacts();
            this.getCoresidentContacts();
        },
    },
    computed: {
        ...mapGetters({
            getGuestCardDetail: 'guest_cards/getGuestCardDetail',
        }),
    },
    methods: {
        ...mapActions({
            updateGuestCard: 'guest_cards/updateGuestCard',
            setCommOptions: 'guest_cards/setCommOptions',
            setGuestCardUUID: 'guest_cards/setGuestCardUUID',
        }),
        async getContacts() {
            this.$guestCardDataProvider
                .get('contacts', {contactId: this.guestCard.contact_id})
                .then(response => {
                    this.guestCard.personalInfo.first_name = response.first_name;
                    this.guestCard.personalInfo.last_name = response.last_name;
                    this.guestCard.contact.email = response.email;
                    this.guestCard.contact.phone = response.phone;
                    this.updateGuestCard(this.guestCard);
                    this.isContactFetched = true;
                })
                .catch();
        },
        async getCoresidentContacts() {
            let ids = this.guestCard.coresidents.map(value => value.contact_id);
            if (ids.length) {
                this.$guestCardDataProvider
                    .list('contacts', {'ids[]': ids})
                    .then(response => {
                        this.guestCard.coresidents.forEach((value, index) => {
                            let contactId = value.contact_id;
                            this.guestCard.coresidents[index] = {
                                ...this.guestCard.coresidents[index],
                                ...response[contactId]
                            }
                        });
                        this.isCoresidentsContactFetched = true;
                    })
                    .catch();
            }
        },
        loadGuestCard() {
            this.updateGuestCard(null);
            this.$guestCardDataProvider
                .get('guestCardDetail', {guestCardId: this.$route.params.guestCardId})
                .then(response => {
                    this.guestCard = response;
                    this.guestCard.personalInfo = {};
                    this.guestCard.contact = {};
                    this.guestCard.fetched = true;
                    this.updateGuestCard(this.guestCard);
                    this.fetchCommFormOptions();
                })
                .catch();
        },
        fetchCommFormOptions() {
            this.$guestCardDataProvider
                .get('communityFormOptions')
                .then((response) => {
                    this.setCommOptions(response);
                    this.loading = false;
                })
                .catch();
        },
        setDefault() {
            this.guestCard.pets = this.guestCard.pets || [];
            this.guestCard.price_quoted = this.guestCard.price_quoted || [];
            this.guestCard.coresidents = this.guestCard.coresidents || [];
        },
        showDiv(divId) {
            let div = this.$refs[divId];
            let top = div.offsetTop;
            let container = this.$refs[this.containerId];
            container.scrollTop = top;
            this.activeDiv = divId;
        },
        setEdit(section, value) {
            this.isEditting[section] = value;
            this.guestCard = this.getGuestCardDetail;
        },
        loadTours() {
            this.$guestCardDataProvider.get(
                'tours',
                { guest_card_id:this.uuid }
            ).then((response) => {
                let result =  response
                result.forEach(tour => {
                    tour.display_time_from    = moment(tour.time_from).format('LT')
                    tour.display_time_to      = moment(tour.time_to).format('LT')
                    tour.display_showing_date = moment(tour.showing_date).format('MM/DD/YY')
                    tour.time_from            = moment(tour.time_from).format('HH:mm:ss').split(" ")[0]
                    tour.time_to              = moment(tour.time_to).format('HH:mm:ss').split(" ")[0]
                });
                this.tours = result
                this.loading = false
            }).catch()
        },
        loadTasks() {
            this.$guestCardDataProvider.get('tasks',{guest_card_id:this.uuid}).then((response) => {
                this.tasks = {
                   open: response?.open ? response.open : [], 
                   closed: response?.closed ? response.closed : [], 
                };
            }).catch()
        },
        reloadNotes(){
            this.$refs.notesList.loadNotes();
        }
    },
    created() {
        this.uuid = this.$route.params.guestCardId
        this.setGuestCardUUID(this.uuid);
        this.loadGuestCard();
        this.loadTours();
        this.loadTasks();
    },
}
</script>
<style>
    .add-new{
        color:#4F98CA !important;
    }
    .add-new-icon{
      margin-top: 2px !important;
    }
    .sidenav {
        width: 25%;
        margin-right: 0rem;
        background: rgb(248, 248, 248);
        border: 2px solid rgb(236, 235, 235);
        padding: 0 1.5rem 0 1.5rem;
        padding-top: 1rem;
    }
    .active {
      background: rgb(231, 237, 247);
      color: var(--highlightColor500) !important;
      box-shadow: 0 0 0 2px rgb(218, 230, 248) inset;
    }
    label {
        margin-bottom: 2px;
        font-size: 1rem;
        padding-left: 1rem;
    }
    .detailsContainer {
        scroll-behavior: smooth;
    }
    .slide-up-enter-active,
    .slide-up-leave-active {
      transition: all 0.25s ease-out;
    }

    .slide-up-enter-from {
      opacity: 0;
      transform: translateY(30px);
    }

    .slide-up-leave-to {
      opacity: 0;
      transform: translateY(-30px);
    }

    .icon-container {
        position: absolute;
        right: 1rem;
        display: none;
    }

    .focus .icon-container {
        display: initial;
    }

    .h-5-6 {
        height: 90%;
    }

    .no-margin {
        margin: 0;
    }
    .footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .input-style{
        border-width: 0;
        border: 1px solid #CECECE;
        padding: 5px;
        max-width: 100%;
    }
</style>
<style scoped>
    div.h-full {
        height: 100%;
    }

    .pos-rel {
        position: relative;
    }

    .icon-container.multi-icon svg {
        display: inline;
        width: 16px;
        height: 16px;
    }
    .h-1\/2 {
        height: 50%;
    }
</style>
