<template>
    <div class="text-black font-thin text-sm">
        <loader :loading="isEditing" :backdrop="true" />

        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">preferred layout</form-label>
                <textarea v-model="apartment.preferred_layout" class="input-style" rows="4" cols="60" />
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">Unit</form-label>
                To be implemented
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/4 pr-8">
                <form-label :light="true">max rent</form-label>
                <div class="flex items-center">
                    <input type="range" :min="minAmount" :max="maxAmount" v-model="apartment.preferred_max_price" />
                </div>
            </div>
            <div class="w-1/4 pr-8">
                <div class="flex justify-start items-center border outline-none font-400 p-3 text-base">
                    <span class="mr-1">$</span>
                    <input type="number" :min="minAmount" :max="maxAmount" class="inline focus:outline-none w-full" v-model="apartment.preferred_max_price" />
                </div>
                <span v-if="hasError('apartment.apartment')" class="form-error">{{ showError('apartment.preferred_max_price') }}</span>
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">lease length</form-label>
                <dropdown v-model="apartment.lease_terms" :options="leaseOptions"></dropdown>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">preferred amenities</form-label>
                <textarea v-model="apartment.preferred_amenities" class="input-style" rows="4" cols="60" />
            </div>
            <div class="w-1/4 pr-8">
                <form-label :light="true">move in start date</form-label>
                <input type="date" class="form-input" v-model="apartment.preferred_movein_date_start"  />
            </div>
            <div class="w-1/4 pr-8">
                <form-label :light="true">move in end date</form-label>
                <input type="date" class="form-input" v-model="apartment.preferred_movein_date_end" />
            </div>
        </div>
        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">preferred bedroom</form-label>
                <!-- TODO: use the one in constants to create loop -->
                <!-- "bedTypes.1BR" causes error on compile on v-model -->
                <div class="inline">
                    <checkbox-input v-for="(bed) in bedOptions" :key="bed.key" v-model="bedTypes[bed.key]" :label="bed.value" class="mr-4" />
                </div>
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">household size</form-label>
                <dropdown :options="householdSizeOptions" v-model="apartment.household_size" />
            </div>
        </div>
        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">preferred bathroom</form-label>
                <dropdown :options="bathroomsOptions" v-model="apartment.preferred_bathrooms" />
            </div>
        </div>
        <div class="text-right mt-4 footer">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="edit">edit</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import Loader from '@/components/ui/Loader';
import Dropdown from "@/components/ui/Dropdown";
import FormLabel from "@/components/ui/FormLabel";
import CheckboxInput from "@/components/ui/CheckboxInput";
import { bedroomTypes } from "@/utils/GuestCards";

export default {
    components: {
        Loader,
        FormLabel,
        Dropdown,
        CheckboxInput,
    },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            apartment: {
                preferred_layout: null,
                preferred_amenities: null,
                preferred_bathrooms: null,
                bedroom_types: "",
                household_size: null,
                preferred_movein_date_start: null,
                preferred_movein_date_end: null,
                lease_terms: null,
                preferred_max_price: 0
            },
            householdSizeOptions: [],
            bathroomsOptions: [],
            minAmount: 0,
            maxAmount: 6000,
            leaseOptions: [],
            isEditing: false,
            bedTypes: {},
            selectedBedTypes: [],
            bedOptions: bedroomTypes,
        }
    },
    emits: ['apartment-info-edited'],
    computed: {
        ...mapGetters({
            getCommOptions: 'guest_cards/getCommOptions'
        })
    },
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        setDefaultBedTypes() {
            this.selectedBedTypes = Object.values(JSON.parse(this.apartment.bedroom_types || "[]"));
            bedroomTypes.forEach(type => {
                this.bedTypes[type.key] = this.selectedBedTypes.includes(type.key);
            });
        },
        close() {
            this.apartment = Object.assign( {}, this.guestCard.apartment);
            this.isEditing = false;
            this.$emit('apartment-info-edited');
        },
        edit() {
            this.isEditing = true;
            let bedroom_types = this.formatBedTypes();
            let dataToUpdate = {
                preferred_layout: this.apartment.preferred_layout,
                preferred_max_price: this.apartment.preferred_max_price,
                lease_terms: this.apartment.lease_terms,
                preferred_amenities: this.apartment.preferred_amenities,
                preferred_movein_date_start: this.apartment.preferred_movein_date_start,
                preferred_movein_date_end: this.apartment.preferred_movein_date_end,
                bedroom_types: bedroom_types,
                household_size: this.apartment.household_size,
                preferred_bathrooms: this.apartment.preferred_bathrooms,
                // unit: 
                apartment: {
                    // unit: 
                    preferred_layout: this.apartment.preferred_layout,
                    preferred_max_price: this.apartment.preferred_max_price,
                    lease_terms: this.apartment.lease_terms,
                    preferred_amenities: this.apartment.preferred_amenities,
                    preferred_movein_date_start: this.apartment.preferred_movein_date_start,
                    preferred_movein_date_end: this.apartment.preferred_movein_date_end,
                    bedroom_types: bedroom_types,
                    household_size: this.apartment.household_size,
                    preferred_bathrooms: this.apartment.preferred_bathrooms,
                }
            }

            this.$guestCardDataProvider
                .update('guestCardDetail', {
                    guestCardId: this.$route.params.guestCardId,
                    data: dataToUpdate,
                })
                .then(response => {
                    console.log(response);
                    this.updateGuestCardInStore(dataToUpdate);
                    this.close();
                })
                .catch();
        },
        formatDates() {
            this.apartment.preferred_movein_date_start = this.apartment.preferred_movein_date_start ? moment(this.apartment.preferred_movein_date_start).format('YYYY-MM-DD') : null;
            this.apartment.preferred_movein_date_end = this.apartment.preferred_movein_date_end ? moment(this.apartment.preferred_movein_date_end).format('YYYY-MM-DD') : null;
        },
        formatBedTypes() {
            let preferredBedTypes = [];
            for (let key in this.bedTypes) {
                if (this.bedTypes[key]) {
                    preferredBedTypes.push(key);
                }
            }

            return "[" + preferredBedTypes.toString() + "]";
        }
    },
    mounted() {
        this.setDefaultBedTypes();
    },
    created() {
        this.apartment = JSON.parse(JSON.stringify(this.guestCard.apartment));
        this.initValidator();
        // this.loadLayoutOptions();
        // this.loadHouseHoldSizeOptions();
        let options = this.getCommOptions;
        this.householdSizeOptions = options.household ? options.household : [{key : 1 , value : "sample"}];
        this.bathroomsOptions     = options.bathrooms ? options.bathrooms : [{key : 1 , value : "sample"}];
        this.leaseOptions          = options.lease_terms || [{key: 1, value: "no leasing options for this community"}];
    }
}
</script>