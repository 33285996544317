<template>
    <div class="h-full">
        <!-- pet form -->
        <div class="h-1-2 overflow-y-auto">
            <div v-for="(pet, index) in pets" :key="'pet-' + index">
                <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                    <div @click="editPet(pet.pet_id)">
                       <span class="font-medium">{{ pet.name   }}</span> - pet {{ (pet.is_service ? " (service animal)" : "")}}
                    </div>
                    <div>
                        <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="remove(pet.pet_id)"></icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full bg-gray-100 p-4 border mt-1 mb-3 h-1-2">
            <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                Add new pet form
            </p>
            <div class="form-row">
                <div class="form-col">
                    <label for="pet_name">name</label>
                    <input type="text" class="form-input" id="pet_name" v-model="newPet.name" />
                </div>
                <div class="form-col">
                    <label>type</label>
                    <dropdown v-model="newPet.type" :options="typeOptions"></dropdown>
                </div>
            </div>
            <div class="w-full flex mt-4">
                <div class=" w-4/6 mr-3">
                    <div style="margin-bottom:10px; font-weight: 600;">breed</div>
                    <dropdown v-model="newPet.breed" :options="breedOptions"></dropdown>
                </div>
                 <div class=" w-2/6 ">
                    <div style="margin-bottom:10px; font-weight: 600;">service animal</div>
                    <toggle-input v-model="newPet.is_service" />
                    <!-- <input type="checkbox"  v-model="newPet.is_service"> -->
                </div>
            </div>

            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="clearAdd">clear form</button>
                <button class="btn-primary ml-2" @click="addPet">{{newPet.pet_id ? 'update pet' : 'add'}}</button>
            </div>
        </div>
        <div class="text-right mt-4 footer">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="update">save edits</button>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import {v4} from "uuid";
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown";
import ToggleInput from "@/components/ui/ToggleInput";
import {birdsOptions, catsOptions, dogsOptions, miscPetsOptions, petTypeOptions} from "@/utils/GuestCards";

const defaultNewPet = {
        name: null,
        breed: null,
        type: null,
        is_service:false
    };

export default {
    components: {
        Icon,
        Dropdown,
        ToggleInput,
    },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            pets: [],
            newPet: {...defaultNewPet},
            typeOptions: petTypeOptions,
            breedOptions: [],
            breeds: {
                Dogs: dogsOptions,
                Cats: catsOptions,
                Birds: birdsOptions,
                Miscellaneous: miscPetsOptions,
            },
        }
    },
    watch: {
        'newPet.type': {
            handler: function() {
                if(this.newPet.type)
                    this.breedOptions = this.breeds[this.newPet.type];
                else {
                    this.breedOptions = [];
                    this.newPet.breed = null;
                }
            },
            immediate: true
        }
    },
    emits: ['pets-edited'],
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        editPet(petId) {
            let searchPet = this.pets.find(item => item.pet_id === petId);
            searchPet.is_service = Boolean(searchPet.is_service);
            this.newPet = {...searchPet};
        },
        remove(petId) {
            let searchedPetIndex = -1;
            let searchPet = this.pets.find((item, index) => {
                let found = item.pet_id === petId;
                if (found) {
                    searchedPetIndex = index;
                    return true;
                }
                return false;
            });
            if (confirm("Are you sure you want to remove this pet?")) {
                this.pets.splice(searchedPetIndex, 1);
                console.log("removing", searchPet);
                console.log("new pets", this.pets);
            }
        },
        clearAdd() {
            this.newPet = {...defaultNewPet};
        },
        addPet() {
            let petIndex = -1;
            if(this.newPet.pet_id) {
                petIndex = this.pets.findIndex(pet => pet.pet_id === this.newPet.pet_id);
                this.pets[petIndex] = {
                    ...this.newPet,
                }
            } else {
                let id = v4();
                let petToAdd = {
                    ...this.newPet,
                    pet_id: id,
                }
                this.pets.push(petToAdd);
            }
            this.clearAdd();
        },
        close() {
            this.pets = JSON.parse(JSON.stringify(this.guestCard.pets));
            this.$emit('pets-edited');
            this.isEditing = false;
        },
        update() {
            this.isEditing = true;
            let dataToUpdate = {
                pets: JSON.parse(JSON.stringify(this.pets)),
            };
            this.updateGuestCardInStore(dataToUpdate);
            this.close();
        }
    },
    created() {
        this.pets = JSON.parse(JSON.stringify(this.guestCard.pets || ""));
    }
}
</script>
<style scoped>
    .footer {
        position: fixed;
        right: 1rem;
        bottom: 1rem;
    }
    .h-1-2 {
        height: 50%;
    }

    .h-full {
        height: 100%;
    }
</style>