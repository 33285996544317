<template> <!-- class="text-black font-thin text-sm" -->
    <div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">name</form-label>
                <loader v-if="!guestCard.personalInfo || !guestCard.personalInfo?.first_name" :loading="!guestCard.personalInfo?.first_name" class="small-loader" :backdrop="false" />
                <p v-else>{{ guestCard.personalInfo.first_name }} {{ guestCard.personalInfo.last_name }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">email address</form-label>
                <loader v-if="!guestCard.contact || !guestCard.contact?.email" :loading="!guestCard.contact?.email" class="small-loader" :backdrop="false" />
                <p v-else>{{ guestCard.contact.email.email_address }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">phone number</form-label>
                <loader v-if="!guestCard.contact || !guestCard.contact?.phone" :loading="!guestCard.contact?.phone" class="small-loader" :backdrop="false" />
                <p v-else>{{ guestCard.contact.phone.phone_number }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">contact preferences</form-label>
                <p>{{ contactPreferences }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">created</form-label>
                <p>{{ createdDate }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">lead source</form-label>
                <p>{{ selectedReferral }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div>
                <form-label :light="true">comments</form-label>
                <p>{{ guestCard.comment }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import FormLabel from "@/components/ui/FormLabel";
import {findValueByKey} from "@/utils/GuestCards";
import Loader from "@/components/ui/Loader";


export default {
    components: { FormLabel, Loader },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getCommOptions: 'guest_cards/getCommOptions',
        }),
        contactPreferences() {
            let contactPreferences = JSON.parse(this.guestCard.contact_preference || "{}");
            let contacts = [];
            if(contactPreferences.email)
                contacts.push('email');

            if(contactPreferences.sms)
                contacts.push('sms');

            if(contactPreferences.phone)
                contacts.push('phone');

            return contacts.join(', ');
        },
        createdDate() {
            return moment(this.guestCard.created_at).format('MM/DD/YYYY');
        },
        selectedReferral() {
            return findValueByKey(this.getCommOptions.lead_source, this.guestCard.source);
        }
    }
}
</script>
<style scoped>
    .small-loader {
        position: relative;
        display: inline-block;
        height: 1rem;
    }
</style>

