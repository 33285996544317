<template>
    <div class="text-black font-thin text-sm" v-if="apartment">
        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">preferred layout</form-label>
                <p>{{ layout }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">Unit</form-label>
                <p>To be implemented</p>
            </div>
        </div>
        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">max rent</form-label>
                <p>{{ maxRent }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">lease length</form-label>
                <p>{{ leaseValue }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">preferred amenities</form-label>
                <p>{{ amenities }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">move in</form-label>
                <p class="lowercase text-sm">{{ moveInDate }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">preferred bedroom</form-label>
                <p>{{ bedRoomsSelected }}</p>
            </div>
            <div class="w-1/2">
                <form-label :light="true">household size</form-label>
                <p>{{ houseHoldSize }}</p>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2">
                <form-label :light="true">preferred bathroom</form-label>
                <p>{{ bathroom }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import FormLabel from "@/components/ui/FormLabel";
import { findValueByKey, bedroomTypes } from "@/utils/GuestCards";

export default {
    components: { FormLabel },
    props: {
        apartment: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        householdSizeOptions: [],
        leaseTerms: [],
    }),
    computed: {
        ...mapGetters({
            getCommOptions: 'guest_cards/getCommOptions'
        }),
        leaseValue() {
            if(this.apartment.lease_terms){
                return findValueByKey(this.leaseTerms, this.apartment.lease_terms);
            }
           return "none";
        },
        moveInDate() {
            let startDate = this.apartment.preferred_movein_date_start ? moment(this.apartment.preferred_movein_date_start).format('MM/DD/YYYY') : "no start date",
                endDate = this.apartment.preferred_movein_date_end ? moment(this.apartment.preferred_movein_date_end).format('MM/DD/YYYY') : "no end date";
            return startDate + " to " + endDate;
        },
        houseHoldSize() {
            return this.apartment.household_size ?  findValueByKey(this.householdSizeOptions, parseInt(this.apartment.household_size)) : 'none';
        },
        bedRoomsSelected() {
            let bedroom = JSON.parse(this.apartment.bedroom_types);
            let bedSelected = [];
            if (bedroom && bedroomTypes) {
                bedroom.forEach(item => {
                    bedSelected.push(findValueByKey(bedroomTypes, item));
                });
            return bedSelected.toString() || "none";

            }
           return "none"
        },
        layout() {
            return this.apartment.preferred_layout || "none";
        },
        maxRent() {
            return this.apartment.preferred_max_price ? "$ " + this.apartment.preferred_max_price : "none";
        },
        amenities() {
            return this.apartment.preferred_amenities || "none";
        },
        bathroom() {
            return this.apartment.preferred_bathrooms || "none";
        }
    },
    methods: {
        getLeaseLengthValue(key) {
            return findValueByKey(this.leaseTerms, key)
        },
    },
    created() {
        let response = this.getCommOptions;
        this.householdSizeOptions = response.household;
        this.leaseTerms = response.lease_terms || [];
    },
}
</script>