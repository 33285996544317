<template>
    <div class="h-5-6 pos-rel">
        <!-- concession form -->
        <div v-if="concessions.length" class="h-1-2-auto overflow-y-auto">
            <div v-for="(concession, index) in concessions" :key="'concession-' + index">
                <div class="px-2 py-1 bg-blue-200 text-blue-800 font-thin mb-2 flex justify-between items-center">
                    <div @click="editContent(concession.concession_id)">
                        {{concession.content.substr(0, maxHeaderString)}}
                        {{concession.content.length > maxHeaderString ? '...' : ''}}
                    </div>
                    <div>
                        <icon name="close" class="w-3 h-3 inline text-blue-300 cursor-pointer" @click="remove(concession.concession_id)"></icon>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            No concessions added yet
        </div>
        <div class="w-full bg-gray-100 p-4 border mt-1 h-form">
            <p class="font-bold text-sm text-black py-1 border-b border-gray-200">
                Concessions content
            </p>
            <div class="form-row">
                    <textarea v-model="newConcession.content" id="concession_content" class="input-style" rows="4" cols="60" />
            </div>

            <div class="mt-4 text-right">
                <button class="btn-primary btn-transparent" @click="clearAdd">clear form</button>
                <button class="btn-primary ml-2" @click="addConcession">{{newConcession.concession_id ? 'update content' : 'add'}}</button>
            </div>
        </div>
        <div class="text-right mt-4">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="update">Update concessions</button>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import {v4} from "uuid";
import Icon from "@/components/ui/Icon";

const defaultConcession = {
        content: ''
    };

export default {
    components: {
        Icon,
    },
    props: {
        guestCard: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            concessions: [],
            newConcession: {...defaultConcession},
            maxHeaderString: 50,
            toRemove: [],
            toAdd: [],
            toUpdate: {},
        }
    },
    emits:['concessions-edited'],
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        remove(concession_id) {
            let searchIndex = -1;
            let searchedConcession = this.concessions.find((item, index) => {
                let found = item.concession_id === concession_id;
                if (found) {
                    searchIndex = index;
                    return true;
                }
                return false;
            });
            if (confirm("Are you sure you want to remove this concession?")) {
                this.concessions.splice(searchIndex, 1);
                this.toRemove.push(concession_id);
                console.log("removing", searchedConcession);
                console.log("new concessions", this.concessions);
            }
        },
        clearAdd() {
            this.newConcession = {...defaultConcession};
        },
        addConcession() {
            if(!this.newConcession.content) {
                alert("Content can't be empty!");
            }
            let concessionIndex = -1;
            if(this.newConcession.concession_id) {
                concessionIndex = this.concessions.findIndex(item => item.concession_id === this.newConcession.concession_id);
                this.concessions[concessionIndex] = {
                    ...this.newConcession,
                }
                this.toUpdate[this.newConcession.concession_id] = this.concessions[concessionIndex];

            } else {
                let id = v4();
                let concessionToAdd = {
                    ...this.newConcession,
                    concession_id: id,
                }
                this.concessions.push(concessionToAdd);
                this.toAdd.push(concessionToAdd);

            }
            this.clearAdd();
        },
        close() {
            this.concessions = JSON.parse(JSON.stringify(this.guestCard.concessions));
            this.$emit('concessions-edited');
            this.toRemove = [];
            this.toAdd = [];
            this.toUpdate = [];
            this.isEditing = false;
        },
        update() {
            this.isEditing = true;
            let dataToUpdate = {
                concessions: JSON.parse(JSON.stringify(this.concessions)),
            },
            updateRequest = {
                concessions: {
                    to_add: this.toAdd,
                    to_delete: this.toRemove,
                    to_modify: this.toUpdate
                },
            };
            this.$guestCardDataProvider
                .update('guestCardDetail', {
                    guestCardId: this.$route.params.guestCardId,
                    data: updateRequest,
                })
            this.updateGuestCardInStore(dataToUpdate);
            this.close();
        },
        editContent(id) {
            let searchConcession = this.concessions.find(item => item.concession_id === id);
            this.newConcession = {...searchConcession};
        },
    },
    created() {
        this.concessions = JSON.parse(JSON.stringify(this.guestCard.concessions || ""));
    }
}
</script>
<style scoped>
    .footer {
        position: absolute;
        right: 0;
        bottom: -2rem;
    }
    .h-1-2-auto {
        max-height: 63%;
    }

    .h-full {
        height: 100%;
    }
    .pos-rel {
        position: relative;
    }

    .h-form {
        margin-bottom: 0px;
        padding-bottom: 10px;
        padding-top: 0.5rem;
    }
</style>