<template>
  <div class="card">
       <div :class="'w-1/8  p-4 border h-full overflow-x relative group' " >
          <loader :loading="loading" :backdrop="true"/>
              <div class="flex mt-4">
                   <div class="w-1/2 relative group">
                       <p class="label">date</p>
                       <p class="value">{{Task.due_date}}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">assigned to</p>
                       <p class="value">{{Task.agent_display_name}}</p>
                   </div>
                   <div class="w-1/2 relative group">
                       <p class="label">last modified</p>
                       <p class="value">{{Task.updated_at}}</p>
                   </div>
                   <!-- <div class="w-1/2 relative group">
                       <p class="label">unit(s)</p>
                       <div class="value flex">
                            <p v-for="(value,index) in tour.units" :key="index">
                            {{ value + ((index + 1) == tour.units.length ? " " : ", ")}}
                            </p>
                       </div>
                   </div> -->
            </div>
            <br />
            <div v-if="!edit">
                <div>
                     <p class="label">description</p>
                     <p class="value">  {{  Task.description}}</p>
                </div>
            </div>
            <br />
             <button @click="reopenTask" class="btn-primary gray-form " type="button">
              <icon name="checkmark" class="w-4 h-4 inline-block mr-1"></icon>
               reopen task
              </button>
            <br />
        </div>
  </div>
</template>

<script>
import { mapActions} from "vuex";
import Icon from "@/components/ui/Icon";
import Loader from '@/components/ui/Loader';
import moment from "moment";
/* import Form from "@/components/form/Form";
import Dropdown from "@/components/ui/Dropdown"; */

export default {
    name:'ClosedTaskCard',
    components:{Icon,Loader},
    props:['task'],
    data(){
        return {
            Task:this.task,
            edit: false,
            units:[],
            tourType:null,
            loading: false,
        }
    },
    mounted(){
         
    },
    emits: ['reload-list'],
    methods: {
        ...mapActions({
        notifySuccess: 'alerts/notifySuccess',
        notifyError: 'alerts/notifyError',
        }),
        cancelEdit(){
            this.edit=false
        },
        reopenTask(){
        let params = {
            id : this.Task.id,
            data : {
                task_id: this.Task.id,              
            }
        }
        this.$guestCardDataProvider.update('reopenTask',params)
            .then(this.emitHandler)
            .catch()
            .finally(() => {
                this.$emit('reload-list');
            })
        },
        emitHandler(response) {
            console.log(response)
            if(response.success){
                this.notifySuccess(response.message);
                this.$emit('reload-list');
            }else{
                this.notifyError(response.message)
            }
        },
        editMode(){
            this.tempUnitOptions.forEach(unitOption => {
                this.Task.units.forEach(tourUnit => {
                    if(unitOption.value == tourUnit){
                        this.units.push(unitOption)
                    }
                });
            });
            this.tourTypes.forEach(type => {
               
                    if(type.value == this.Task.tour_type){
                        this.tourType = type
                    }
              
            });
            this.edit = true
        },
    },
    created() {
        this.Task.due_date = moment(this.task.due_date).format("MM/DD/YYYY");
        this.Task.updated_at = moment(this.task.updated_at).format("MM/DD/YYYY");
    }
}
</script>
<style scoped>
    .label{
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
     .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
     .gray-form{
      border-color: gray;
      color: gray;
    }
</style>