<template>

    <div class="text-black font-thin text-sm overflow-y-auto h-5-6">
        <loader :loading="isEditing" :backdrop="true" />

        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">first name</form-label>
                <input type="text" class="form-input" v-model="personalInfo.first_name" autocomplete="off" />
                <span v-if="hasError('personalInfo.first_name')" class="form-error">{{ showError('personalInfo.first_name') }}</span>
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">last name</form-label>
                <input type="text" class="form-input" v-model="personalInfo.last_name" autocomplete="off" />
                <span v-if="hasError('personalInfo.last_name')" class="form-error">{{ showError('personalInfo.last_name') }}</span>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">phone</form-label>
                <input type="text" class="form-input" v-model="contact.phone.phone_number" autocomplete="off" />
                <span v-if="hasError('contact.phone.phone_numbere')" class="form-error">{{ showError('contact.phon.phone_numbere') }}</span>
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">email</form-label>
                <input type="text" class="form-input" v-model="contact.email.email_address" autocomplete="off" />
                <span v-if="hasError('contact.email.email_address')" class="form-error">{{ showError('contact.email.email_address') }}</span>
            </div>
        </div>

        <div class="flex mt-4">
            <div class="w-1/2 pr-8">
                <form-label :light="true">contact preferences</form-label>
                <div class="flex flex-col">
                    <div class="inline">
                        <checkbox-input label="email" v-model="contactPreferences.email" class="mr-4" />
                        <checkbox-input label="sms" v-model="contactPreferences.sms" class="mr-4" />
                        <checkbox-input label="phone" v-model="contactPreferences.phone" />
                    </div>
                </div>
            </div>
            <div class="w-1/2 pr-8">
                <form-label :light="true">lead source</form-label>
                <dropdown
                    :options="referralOptions"
                    v-model="source"
                    class="mt-1"
                ></dropdown>
            </div>
        </div>
        <div class="mt-4 pr-8 h-1-3">
            <form-label :light="true">comments</form-label>
            <textarea v-model="comment" class="border w-full p-4 mt-4 focus:outline-none h-full" />
        </div>

        <div class="text-right mt-4 footer">
            <button class="btn-transparent" @click="close">cancel</button>
            <button class="btn-primary ml-2" @click="edit">edit</button>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/ui/Loader";
import Dropdown from "@/components/ui/Dropdown";
import FormLabel from "@/components/ui/FormLabel";
import CheckboxInput from "@/components/ui/CheckboxInput";
import {mapActions, mapGetters} from "vuex";

export default {
    components: { Loader, FormLabel, Dropdown, CheckboxInput },
    props: {
        guestCard: {
            type: Object,
            required: true,
            default: () => { return {} }
        }
    },
    data: () => {
        return {
            personalInfo: {
                first_name: null,
                last_name: null,
            },
            contact: {
                email: false,
                sms: false,
                phone: false
            },
            referral: null,
            referralOptions: [],
            comments: null,
            isEditing: false,
            sourceLabel: "",
            tempDetails: {},
            source: null,
        }
    },
    emits: ['personal-info-edited'],
    computed: {
        ...mapGetters({
            getGuestCardDetail: 'guest_cards/getGuestCardDetail',
            getCommOptions: 'guest_cards/getCommOptions',
        }),
    },
    methods: {
        ...mapActions({
            updateGuestCardInStore: 'guest_cards/updateGuestCard'
        }),
        close() {
            this.isEditing = false;
            this.$emit('personal-info-edited');
        },
        edit() {
            this.isEditing = true;
            let dataToUpdate = {
                contact_preference: JSON.stringify(this.contactPreferences), //convert to string before passing to backend
                comment: this.comment,
                contact: {
                    name: {
                        given_name: this.personalInfo.first_name,
                        family_name: this.personalInfo.last_name,
                    },
                    email: this.contact.email,
                    phone: this.contact.phone,
                    sms: this.tempDetails.contact.sms,
                },
                source: this.source,
            }
            this.$guestCardDataProvider
                .update('guestCardDetail', {
                    guestCardId: this.$route.params.guestCardId,
                    data: dataToUpdate,
                })
                .then(response => {
                    console.log(response);
                    let updateStore = {
                        personalInfo: {
                            first_name: dataToUpdate.contact.name.given_name,
                            last_name: dataToUpdate.contact.name.family_name,
                        },
                        contact: {
                            email: dataToUpdate.contact.email,
                            phone: dataToUpdate.contact.phone,
                        }
                    };
                    this.updateGuestCardInStore(updateStore);
                    this.close();
                })
                .catch();
        },
    },
    validations: {
        'personalInfo.first_name': 'required',
        'personalInfo.last_name': 'required',
        'contact.email': 'required | email',
        'contact.phone': 'required',
    },
    created() {
        this.tempDetails = JSON.parse(JSON.stringify(this.getGuestCardDetail));
        this.initValidator();
        this.personalInfo = this.tempDetails.personalInfo;
        this.referralOptions = this.getCommOptions.lead_source
        this.contact = this.tempDetails.contact;
        this.source = this.tempDetails.source;
        this.comment = this.tempDetails.comment;
        this.contactPreferences = JSON.parse(this.tempDetails.contact_preference);
    }
}
</script>
<style>
    .inline > label div,
    .inline > label {
        display: inline-block;
        margin-bottom: -3px;
        margin-right: 1rem;
    }
    div.inline > label div {
        margin-right: 0.5rem;
    }

    .inline > label div span {
        display: initial;
    }
    .inline > label div span.hidden {
        display: none;
    }
    .footer {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .h-1-2 {
        height: 50%;
    }

    .h-full {
        height: 95%;
    }

    .h-1-3 {
        height: 39%;
    }
</style>
