<template>
  <div style="height: 95%; overflow: auto;">
    <AddTourCard @new-data="loadTours" ></AddTourCard>
    <br/>
    <loader :loading="loading" :backdrop="true"/>
      <div v-for="tour in tours" :key="tour.id"> 
        <tour-card :tour="tour" :vacantUnits="availableUnits"></tour-card><br/>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters} from "vuex";
import Loader from '@/components/ui/Loader';
import AddTourCard from "@/components/guest_cards/tours/AddTourCard";
import TourCard from "@/components/guest_cards/tours/TourCard";
export default {
    name:'ToursList',
    components:{TourCard,AddTourCard,Loader},
    data(){
        return {
           tours:[                 
                ],
            loading:false,
            uuid:null,
            units: []
        }
    },
    computed: {
        ...mapGetters({
            getGuestCardUUID: 'guest_cards/getGuestCardUUID'
        }),
        availableUnits(){
          let availableUnits =[];
          this.units.forEach((location)=>{
            availableUnits = availableUnits.concat(location.units)
          })
          return availableUnits
        }
    },
    methods: {
        loadTours(){
                this.loading = true
                this.$guestCardDataProvider.getList('tours',{guest_card_id:this.uuid}).then((response) => {
                  let result =  response
                   result.forEach(tour => {
                       tour.display_time_from    = moment(tour.time_from).format('LT')
                       tour.actualShowingDated   = moment(tour.showing_date).format('YYYY-MM-DD')
                       tour.display_time_to      = moment(tour.time_to).format('LT')
                       tour.display_showing_date = moment(tour.showing_date).format('MM/DD/YYYY')
                       tour.time_from            = moment(tour.time_from).format('HH:mm:ss').split(" ")[0]
                       tour.showing_date         = moment(tour.showing_date).format('MM/DD/YYYY')
                       tour.followup_date        = tour.followup_date ? moment(tour.followup_date).format('MM/DD/YYYY') : null
                   });
                this.tours = result
                this.loading = false
            }).catch()
        }
    },
    created() {
      this.uuid = this.getGuestCardUUID;
      this.loadTours()
      this.$guestCardDataProvider
                .get('getAvailableUnits')
                .then((response) => {
                 this.units = response;
                 this.laoding = false
                })
                .catch((err) => {
                  console.log(err)
                  this.laoding = false
        })
    }
}
</script>