<template>
  <div class="">
       <span class="flex justify-right text-blue-500 text-sm cursor-pointer select-none " @click="isOpen = !isOpen">
                    <icon v-if="!isOpen" name="plus" class="w-4 h-4 inline-block mr-1"></icon>
                    <icon v-else name="minus" class="w-4 h-4 inline-block mr-1"></icon>
                    <span class="mb-1">add a task</span>
        </span>
        <loader :loading="loading" :backdrop="true"/>
       <Form :submit="saveTask">
            <div class="w-1/8  p-4 border bg-gray-100 h-full  relative group" v-if="isOpen">
               
            <div class="p-4 mt-1 mb-3 " >
                 <div>
                     <div>

                         <label for="description">{{"task description"}}</label>
                         <textarea v-model="task.description" class="border w-full h-24 p-4 mt-4 focus:outline-none"></textarea>
                     </div>
                 </div>
                 <br />
                    <div class="flex" style="width:100% !important;">
                      <div class="flex justify-left" style="width:50% !important;">
                        <button @click="cancel" class="btn-primary " type="button">cancel</button>
                      </div> 
                      <div class="flex justify-right" style="width:50% !important;">  
                        <button @click="saveTask" class="btn-primary btn-solid " type="submit"> save</button>
                      </div>
                    </div>
             
                </div>
            </div>
        </Form>
  </div> 
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import { mapGetters, mapActions} from "vuex";
import Form from "@/components/form/Form";
import Icon from "@/components/ui/Icon";
import moment from "moment";
import Loader from '@/components/ui/Loader';

/* import EventBus from "@/utils/EventBus"; */
export default {
    name:'AddTaskCard',
    mixins:[AuthMixin],
    components:{
      Icon,
      Form,
      Loader,
    },
    data(){
        return {
            uuid: null,
            task:{
              guest_card_id:null,
              agent_display_name : 'Temporary Agent',
              date: moment().format('YYYY-MM-DD'),
            
             
              description: null
            },
            isOpen:false,
            agent_profile: {},
            loading: false,
        }
    },
    emits: ['taskCreated'],
    mounted(){
       /*  this.agent_profile = this.profile */
      /*  console.log(this.$store) */
    },
    computed: {
        ...mapGetters({
            getGuestCardUUID: 'guest_cards/getGuestCardUUID',
            profile : 'auth/profile'
        }),
        currentDate(){
          return moment().format('YYYY-MM-DD');
        },
        tempUnitOptions(){
          return [
            {
              key:0,
              value: "404"
            },
            {
              key:1,
              value: "200"
            },
            {
              key:3,
              value: "405"
            },
            {
              key:4,
              value: "406"
            }
          ]
        },
        tourTypes(){
          return [
            {
              key:0,
              value: "Virtual"
            },
            {
              key:1,
              value: 'In-person'
            },
            {
              key:3,
              value: 'Media'
            },
          
          ]
        }
     
    },
    methods: {
        ...mapActions({
            notifySuccess: 'alerts/notifySuccess',
            notifyError: 'alerts/notifyError',
        }),
        loadTours(){
           
        },
        saveTask(){
            this.loading = true;
            this.task.guest_card_id = this.uuid,
            this.task.agent_display_name = this.profile.firstName+ " "+this.profile.lastName 
            this.$guestCardDataProvider.create('tasks',this.task).then((response) => {
              console.log(response)
                if(response){
                  this.notifySuccess('Task saved successfully.');
                   this.$emit('taskCreated',response.data)
                   this.resetTour();
                   this.loading = false;
                }
                 
            }).catch()
            this.isOpen = false
        },
        resetTour(){
             this.task = {
              agent_display_name : this.profile.firstName+ " "+this.profile.lastName ,
              showing_date: moment().format('YYYY-MM-DD'),
              description: null
            }
        },
        cancel(){
            this.resetTour()
            this.isOpen = false
        }
    },
    created() {
        this.uuid = this.getGuestCardUUID;
    }
}
</script>
<style scoped>
    .label{
        color:#c4c4c4;
        font-family: 'Frank New';
        font-size: 12px;
    }
    .value{
        font: normal normal normal 12px/18px 'Inter';
        letter-spacing: 0.72px;
        font-weight: 600;
    }
    .justify-right{
        justify-content: right !important;
    }
    .justify-left{
        justify-content: left !important;
    }
</style>